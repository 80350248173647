/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
       #map {
        height: 60%;
        width: 100%;
        overflow: hidden;
      }
      #description {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 300;
      }

      #infowindow-content .title {
        font-weight: bold;
      }

      #infowindow-content {
        display: none;
      }

      #map #infowindow-content {
        display: inline;
      }

      .pac-card {
        margin: 10px 10px 0 0;
        border-radius: 2px 0 0 2px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        outline: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        font-family: Roboto;
      }

      #pac-container {
        padding-bottom: 12px;
        margin-right: 12px;
      }

      .pac-controls {
        display: inline-block;
        padding: 5px 11px;
      }

      .pac-controls label {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 300;
      }

      #pac-input {
        background-color: #fff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 300;
        padding: 0 10px;
        text-overflow: ellipsis;
        width: 100%;
        height: 36px;
        display: block;
      }

      #pac-input:focus {
        border-color: #4d90fe;
      }

      #title {
        color: #fff;
        background-color: #4d90fe;
        font-size: 25px;
        font-weight: 500;
        padding: 6px 12px;
      }
      #target {
        width: 345px;
      }
      .map_address_list {
          height: calc(40% - 36px);
          width: 100%;
          overflow-y: auto;
      }
      .map_address_item {
          font-size: 18px;
          line-height: 24px;
          padding: 10px 5px;
          border-bottom: 1px solid black;
          width: 100%;
          cursor: pointer;
      }