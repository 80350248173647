.cardListWrapper{
    background: #fff;
    padding: 70px 54px;
}

.cardListWrapper  li{
    list-style: none;
}
.cardTitle {
    color: black;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.total{
    font-size: 13px;
    color: #999;
    margin-left: -20px;
}
.total:after{
    content:'';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #999;
    border-right: 1px solid #999;
    transform-origin: center center;
    -moz-transform-origin: center center;
    -webkit-transform-origin:center center;
    -o-transform-origin:center center;
    transform: rotate(45deg);
    
}




.cardList .item{
    margin: 0 28px;
    box-shadow: 0px 4px 10px 0px rgba(209, 213, 223, 0.5);
    border-radius: 8px;
    padding: 15px;
    display: flex;
    margin-bottom: 20px;
    font-size: 15px;
    color: #333;
}

.item .exp{
    font-size:13px;
    color: #999;
    flex: 1;
}

.item .brand{
    font-size: 15px;
    color: #333;
    flex: 1;
}

.item .code{
    font-size: 15px;
    color: #333;
    flex: 10;
}

.item .check{
    content:'';
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #D8D8D8;
    border-radius: 50%;
    margin-top: 5px;
}

.item .check.checked{
    content:'';
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #D8D8D8;
    border-radius: 50%;
    background: #B20202 url(../images/right.png) no-repeat center center;
    background-size: contain;
}


.cardHolderInfo {
    border: 1px solid #aab7c4;

    border-radius: 10px;
    overflow: hidden;
}

.cardHolderInfoInput {
    display: block;
    color: #424770;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.025em;
    padding: 0 10px;
    border: 0;
    width: 100%;
    border-bottom: 1px solid #aab7c4;
}
.cardHolderInfoInput:last-child {
    border-bottom: none;
}
.cardHolderInfoInput::placeholder {
    color: #aab7c4;
}

:global .card_collapse .ant-collapse{
    background: transparent;
    border: none;
}

:global .card_collapse .ant-collapse > .ant-collapse-item {
    border-bottom: none;
}


:global .ant-collapse-content > .ant-collapse-content-box{
    padding: 0px 40px 40px!important;
    border-top: none!important;
}
.ant-collapse-content > .ant-collapse-content-box{
    padding: 40px!important;
    border-top: none!important;
}

.ant-collapse-content{
    border-top: none!important;
}

:global .ant-collapse-content{
    border-top: none!important;
}
header{
    overflow: hidden;
    padding-right: 50px;

}

.button{
    float: right;
    margin-bottom: 30px;
}

